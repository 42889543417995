<template>
    <div>
        <TableList
            :columns="columns"
            :get_table_list="this.get_table_list"
            :form_data_seo="form_data_seo"
            :rowSelection="rowSelection"
            @get_date = 'get_date'
            :slot_table_list="['operation','status','visit_num','patrol_num','order_price','investment_price','binding_num','is_line']"
            :submit_preprocessing="submit_preprocessing"
            ref="list"
			:is_created_get_list="false"
            :rowKey="'id'"
			:scroll="{x:2300}"
            :chose_parent="true"
        >
            <template slot="table_before">
                <a-alert
                    v-if="selectedRowKeys.length"
                    style="margin-bottom:10px"
                    :message="'已选择'+selectedRowKeys.length+'项'"
                    type="info"
                    showIcon
                />
            </template>
            <template slot="left_btn">
                <a-button
                    v-auth="'change'"
                    @click="add()"
                    type="primary"
                >添加客户</a-button>
				<!-- 批量修改经销商也需要做和“批量更改业务员”按钮一样的显示隐藏判断 -->
				<a-button
				    type="primary"
					v-if="this.$store.state.page_auth.batch"
				    style="margin-left: 10px;"
				    @click="start_distributor_update(1)"
				    :loading="loading"
				>批量修改上级配送商</a-button>
				<a-button
				    type="primary"
					v-if="this.$store.state.page_auth.batch"
				    style="margin-left: 10px;"
				    @click="start_distributor_update(2)"
				    :loading="loading"
				>批量修改所属经销商</a-button>
                <a-button
                	@click="pu_distributor"
                	type="primary"
                    style="margin-left: 10px;">同步分销商</a-button>
                    <!-- :disabled="!hasdistributor" -->
                <a-button
                	@click="pu_seller"
                	type="primary"
                    style="margin-left: 10px;">同步经销商</a-button>
                    <!-- :disabled="!hasseller" -->
                <a-button
                    type="primary"
                    v-if="this.$store.state.page_auth.batch"
                    style="margin-left: 10px;"
                    @click="start"
                    :disabled="!hasSelected"
                    :loading="loading"
                >批量更改业务员</a-button>
				
                <!-- <a-button
                    v-if="this.$store.state.page_auth.batch"
                    style="margin-left: 10px;"
                    @click="handle_transfer"
                >转移客户</a-button> -->

				<!-- <a-popconfirm title="您确定要删除吗?" v-if="this.$store.state.page_auth.delete" @confirm="handle_all_delete">
                    <a-button
					:disabled="!hasSelected"
					 type="primary"
                    	style="margin-left: 10px;">批量删除</a-button>
                </a-popconfirm> -->

				<a-button
					v-auth="'change'"
					@click="handle_all_enable"
					:disabled="!hasSelected"
					 type="primary"
                    	style="margin-left: 10px;">批量启用</a-button>
				<!-- <a-popconfirm title="您确定要删除吗?" v-if="this.$store.state.page_auth.delete" @confirm="handle_all_delete"> -->
                <!-- </a-popconfirm> -->
				<a-popconfirm title="您确定要封存吗?" v-if="this.$store.state.page_auth.sealed" @confirm="handle_all_sealed">
					<a-button
						style="margin-left: 10px;"
						:disabled="!hasSelected"
						type="primary"
					>批量封存</a-button>
				</a-popconfirm>
                
            </template>
            <template slot="right_btn">
                <a v-if="this.$store.state.page_auth.export" @click="goReport()">
					<a-button class="a_btn">导出</a-button>
				</a>
                <import-upload
                    v-if="this.$store.state.page_auth.import"
                    url="/manage/crm.export/customerImport"
                ></import-upload>
                <export-table
                    v-if="this.$store.state.page_auth.import"
                    name="模板下载"
                    url="/static/excel/客户导入模板.xlsx"
                ></export-table>
				<a-button @click="toOpLog" style="margin-left: 15px;" type="primary">操作日志</a-button>
            </template>
			
			<!-- v-for="item in ['visit_num','','','']" :key="item" -->
			<template  slot="visit_num" slot-scope="data">
				<router-link :to="{path:'/imitation/index',query:{customer:data.record.id,flag:'customer'}}">{{data.text}}</router-link>
            </template>
			<template  slot="patrol_num" slot-scope="data">
				<router-link :to="{path:'/imitation/index',query:{customer:data.record.id,flag:'customer',type:3,flag:'patrol'}}">{{data.text}}</router-link>
			</template>
			
			
			<template  slot="order_price" slot-scope="data">
				<router-link :to="{path:'/order/index',query:{customer:data.record.id}}">{{data.text}}</router-link>
            </template>
			<template  slot="investment_price" slot-scope="data">
				<router-link :to="{path:'/approval/investment',query:{customer:data.record.id}}">{{data.text}}</router-link>
            </template>
			<template  slot="binding_num" slot-scope="data">
				<router-link :to="{path:'/good_list/freezer',query:{customer:data.record.id}}">{{data.text}}</router-link>
            </template>
			<template  slot="is_line" slot-scope="data">
				<span v-if="data.text==1">已排线</span>
				<span v-else>未排线</span>
            </template>
            <template slot="status" slot-scope="data">
                <a-tag
                    :color="config.color[customer_status_color(data.text)]"
                    v-for="item in status_list"
                    :key="item.key"
                    v-show="item.key == data.text"
                >{{item.value}}</a-tag>
            </template>
			<!-- v-auth="'change'" -->
            <template slot="operation" slot-scope="data">
                <span>
                    <a v-auth="'change'" @click="edit(data.record)">编辑</a>
                </span>
            </template>
        </TableList>

        <!-- 选择用户 -->
        <SelectUser @submit="submit" :visible.sync="user_visible"></SelectUser>

        <!-- 转移客户 -->
        <EditPop
            :form_data="transfer_form_data"
            ref="transfer_form"
            :destroyOnClose="true"
            :visible.sync="transfer_visible"
            @handleSubmit="transfer_submit"
        >
            <div @click="get_uid('uid')" slot="select_uid">
                <a-input v-model="transfer_list.uid"></a-input>
            </div>
            <div @click="get_uid('to_uid')" slot="select_to_uid">
                <a-input v-model="transfer_list.to_uid"></a-input>
            </div>
        </EditPop>
        <a-modal
			title="提示"
			:visible="visible_updata"
			:closable='false'
			:closeIcon='false'
			:maskClosable='false'
		>
			<template slot="footer">
				<a-button @click="handleCancel" v-if='up_cancel'>关闭</a-button>
				<span v-else></span>
			</template>
		  
			<p v-if="up_cancel">同步成功！</p>
			<p v-else>正在从DRP系统中同步{{pu_type == 1 ? "经销商" : "分销商"}}，大约需要一分钟，同步成功后，会在此页面告知您！</p>
		</a-modal>
		
		<!-- 批量修改经销商弹窗 -->
		<a-modal
			:title="select_type == 1?'批量修改上级配送商':'批量修改所属经销商'"
			:visible="distributor_update_pop"
			@cancel="distributor_update_pop = false"
			@ok="distributor_update_confirm"
		>
			<div class="has_seleted_customer">
				<div class="select_item">
					<div class="must_dot">要修改的{{ select_type == 1? '上级配送商' : '所属经销商' }}：</div>
					<a @click="distributor_type = 'old_distributor';distributor_select_pop = true">{{distributor_update_form.old_distributor ? (distributor_update_form.old_distributor.name || '请选择') : '请选择'}}</a>
				</div>
				<div class="select_item">
					<div class="must_dot">修改后{{ select_type == 1? '上级配送商' : '所属经销商' }}：</div>
					<a @click="distributor_type = 'new_distributor';distributor_select_pop = true">{{distributor_update_form.new_distributor ? (distributor_update_form.new_distributor.name || '请选择') : '请选择'}}</a>
				</div>
				
				<div class="select_item">
					<div class="must_dot">修改原因：</div>
					<a-textarea
					    v-model:value="distributor_update_form.reason"
					/>
				</div>
			</div><!-- has_seleted_customer -->
		</a-modal>
		<!-- 经销商选择弹窗 -->
        <SelectCustomer ref="SelectCustomer" @submit="select_customer_submit" :visible.sync="distributor_select_pop" :seo_data="selectCustomerSeoData"></SelectCustomer>
    </div>
</template>

<script>
// @ is an alias to /src
import EditPop from "@/components/EditPop";
import Form from "@/components/Form";
import TableList from "@/components/TableList";
import SelectUser from "@/components/SelectUser";
import SelectCustomer from "@/components/SelectCustomer";
import { getCustomerList, batchModify, delCustomer, sealedCustomer ,enableCustomer,push_seller,push_distributor,batchDistributor,getCustomerNatureSelect} from "@/api/customer";
import { getUserList } from "@/api/personnel";
import { message } from 'ant-design-vue'

const columns = [
    {
        title: "客户ID",
        dataIndex: "id"
    },
    
    {
        title: "客户名称",
        dataIndex: "name"
    },
    {
        title: "客户地址",
        dataIndex: "address"
    },
	{
	    title: "录入时间",
	    dataIndex: "create_time",
		width:150,
	},
    {
        title: "客户等级",
        dataIndex: "level"
    },
    {
        title: "客户类型",
        dataIndex: "group"
    },
    {
        title: "客户状态",
        dataIndex: "status",
        scopedSlots: {
            customRender: "status"
        }
    },
	{
	    title: "绑定小程序人数",
	    dataIndex: "bind_user",
	    
	},
	{
        title: "排线状态",
        dataIndex: "is_line",
        scopedSlots: {
            customRender: "is_line"
        }
    },
    {
        title: "业务员",
        dataIndex: "username"
    },
    {
        title: "部门",
        dataIndex: "department"
    },
	{
		title: "拜访频率",
		dataIndex: "visit_frequency"
	},
    {
        title: "拜访次数",
        dataIndex: "visit_num",
        scopedSlots: {
            customRender: "visit_num"
        }
    },
	{
	    title: "巡查次数",
	    dataIndex: "patrol_num",
	    scopedSlots: {
	        customRender: "patrol_num"
	    }
	},
	
    {
        title: "订单上报金额",
        dataIndex: "order_price",
        scopedSlots: {
            customRender: "order_price"
        }
    },
    {
        title: "费用投放金额",
        dataIndex: "investment_price",
        scopedSlots: {
            customRender: "investment_price"
        }
    },
    {
        title: "冰柜数量",
        dataIndex: "binding_num",
        scopedSlots: {
            customRender: "binding_num"
        }
    },
    {
        title: "最后拜访时间",
        dataIndex: "last_visit",
		width:200,
    },
	{
	    title: "封存时间",
	    dataIndex: "seal_up",
		width:200,
	},
	{
	    title: "封存人",
	    dataIndex: "seal_user",
		width:100,
	},
    {
        title: "操作",
        dataIndex: "operation",
        scopedSlots: {
            customRender: "operation"
        }
    }
];

let status_list = [
    {
        key: 1,
        value: '运营中'
    },
    {
        key: -1,
        value: '封存'
    },
];

export default {
    name: "CustomerIndex",
    components: {
		SelectCustomer,
        EditPop,
        Form,
        TableList,
        SelectUser
    },
    data() {
        return {
            visible_updata:false,
            pu_type:0,
            hasseller:false,
            hasdistributor:false,
            config: this.$config,
            get_table_list: getCustomerList,
            get_pop_table_list: getUserList,
            submit_preprocessing: {
                array_to_string: ['department_id', 'group_id']
            },
            columns,

            status_list,
            selectedRowKeys: [],// table中选中的客户id
            selectedRows: [],// table中选中的客户数据
            loading: false,
            visible: false,
            transfer_type: 'jump_customer',
            transfer_name: 'uid',
            transfer_list: {
                uid: '',
                to_uid: ''
            },
            transfer_form_data: {
                title: '转移客户',
                list: [
                    {
                        type: "slot",
                        name: "select_uid",
                        title: "原业务员",
                        options: {},
                    },
                    {
                        type: "text",
                        name: "uid",
                        hidden: true,
                        title: "原业务员",
                        options: {},
                    },
                    {
                        type: "slot",
                        name: "select_to_uid",
                        title: "新业务员",
                        options: {},
                    },
                    {
                        type: "text",
                        name: "to_uid",
                        hidden: true,
                        title: "新业务员",
                        options: {},
                    },
                ]
            },
            transfer_visible: false,
            user_visible: false,

            form_data_seo: {
                list: [
                    {
                        type: "tree-select",
                        name: "department_id",
                        title: "部门",
                        options: {},
                        treeData: [],
                        multiple: true
                    },
                    {
                        type: "text",
                        name: "keyword",
                        title: "客户",
                        placeholder: "客户名称/地址/ID",
                        options: {}
                    },
                    {
                        type: "text",
                        name: "username",
                        title: "业务员",
                        placeholder: "姓名/账号/工号/手机号",
                        options: {}
                    },

                    {
                        type: "select",
                        name: "incumbency_status",
                        title: "在职状态",
                        mode: "default",
                        options: {},
                        list: this.$config.user_status_list
                    },
                    {
                        type: "select",
                        name: "level_id",
                        title: "客户等级",
                        mode: "default",
                        options: {},
                        list: []
                    },
                    {
			            type: "tree-select",
			            name: "group_id",
			            title: "客户类型",
			            options: {},
			            treeData: [],
			            multiple: true
			        },
                    // {
                    //     type: "cascader",
                    //     name: "group_id",
                    //     title: "客户类型",
                    //     list: [],
                    //     placeholder: "请选择客户类型",
                    //     mode: "default",
                    //     options: {},
                    // },
                    {
                        type: "select",
                        name: "is_line",
                        title: "排线状态",
                        mode: "default",
                        options: {},
                        list: [
                            {
                                key: "0",
                                value: "未排线"
                            },
                            {
                                key: "1",
                                value: "已排线"
                            },
                        ]
                    },
                    {
                        type: "select",
                        name: "status",
                        title: "客户状态",
                        mode: "default",
                        options: {},
                        list: [
							{
							    key: "0",
							    value: "全部"
							},
                            {
                                key: "1",
                                value: "运营中"
                            },
                            {
                                key: "-1",
                                value: "封存"
                            },
                        ]
                    },
					{
						type: "select",
						name: "customer_nature",
						title: "桶装/瓶装",
						options: {
							initialValue: ''
						},
						list: [{
							key: 1,
							value: "桶装"
						}, {
							key: 2,
							value: "瓶装"
						}]
					},
					{
						type: "select",
						name: "nature_id",
						title: "客户性质",
						list:[],
						options: {
							initialValue: ''
						},
					},
                    // {
                    //     type: "select",
                    //     name: "is_department",
                    //     title: "是否绑定部门",
                    //     mode: "default",
                    //     options: {},
                    //     list: [
                    //         {
                    //             key: "2",
                    //             value: "已绑定"
                    //         },
                    //         {
                    //             key: "1",
                    //             value: "未绑定"
                    //         },
                    //     ]
                    // },
                    {
                        type: "select",
                        name: "is_freezer",
                        title: "是否绑定冰柜",
                        mode: "default",
                        options: {},
                        list: [
                            {
                                key: "1",
                                value: "是"
                            },
                            {
                                key: "0",
                                value: "否"
                            },
                        ]
                    },
                    {
                        type: "select",
                        name: "order_status",
                        title: "是否有未完成订单",
                        mode: "default",
                        options: {},
                        list: [
                            {
                                key: "1",
                                value: "是"
                            },
                            {
                                key: "-1",
                                value: "否"
                            },
                        ]
                    },
                    {
                        type: "select",
                        name: "cost_status",
                        title: "是否有未处理合约",
                        mode: "default",
                        options: {},
                        list: [
                            {
                                key: "1",
                                value: "是"
                            },
                            {
                                key: "-1",
                                value: "否"
                            },
                        ]
                    },
					{
						type: "select",
						name: "visit_frequency",
						title: "拜访频率",
						mode: "default",
						options: {},
						list: [
							{
								key: "1",
								value: "每周1访"
							},
							{
								key: "2",
								value: "每周2访"
							},
							{
								key: "3",
								value: "每周3访"
							},
							{
								key: "4",
								value: "每周4访"
							},
							{
								key: "5",
								value: "每周5访"
							},
							{
								key: "6",
								value: "每周6访"
							},
							{
								key: "7",
								value: "每周7访"
							},
						]
					},
					{
						type: "range_date",
						name: "range_date",
						title: "拜访时间",
						options: {},
						start: {
						    name: 'start_time'
						},
						end: {
						    name: 'end_time'
						},
					}
                ],
                ...this.$config.form_data_seo
            },
            customer_ids: '',
            up_cancel:false,
            select_user: [],
			distributor_select_pop:false,// 经销商选择弹窗是否显示
			distributor_update_pop:false,// 批量修改经销商弹窗是否显示
			// 批量修改经销商的表单内容
			distributor_update_form:{
				// old_distributor:{},// 要修改的经销商
				// new_distributor:{},// 修改后经销商
				// reason:'',// 修改原因
			},
			distributor_type:'',// 用来判断批量修改经销商弹窗中点击选择经销商是选择“要修改的经销商(old_distributor)”还是“修改后经销商(new_distributor)”
			selectCustomerSeoData:{
				customer_type:'dealer', // eg: customer_type:'dealer'，目前该字段只有dealer值，用于只筛选出经销商
			},// 选择用户组件中的搜索传参
			select_type: 1
        };
    },

    computed: {
		// 客户状态的颜色
		customer_status_color(){
			return function(status){
				if(status === 1){
					return 'primary'
				}else if(status === -1){
					return 'error'
				}
			}
		},
        hasSelected() {
            return this.selectedRowKeys.length > 0;
        },
        rowSelection() {
            const { selectedRowKeys } = this;
            return {
                selectedRowKeys,
                onChange: (selectedRowKeys, selectedRows) => {
					// 由于selectedRows在翻页后不会保留之前选中的数据，所以需要在此处进行额外处理
					// 在这本次selectedRows之前已经选中了的用户
					let prevSelectedRows = this.selectedRows;
					// 将变化前的selectedRows与现在的selectedRows进行结合
					let selectedCustomerInfo = [...prevSelectedRows,...selectedRows];
					// 由于可能会出现重复，所以进行去重处理
					let userArr = [];
					let flag;
					for (let i in selectedCustomerInfo){
					    flag = true;
					    for (let j in userArr) {
					        if (userArr[j].id == selectedCustomerInfo[i].id) {
					            flag = false;
					        }
					    }
					    if (flag) {
					      userArr.push(selectedCustomerInfo[i]);
					    }
					}
					this.selectedRows = userArr;
					
                    this.selectedRowKeys = selectedRowKeys;
                    this.customer_ids = selectedRowKeys.join(',')
                },
                hideDefaultSelections: true,
            };
        }
    },
	deactivated() {
		
	},
	activated() {
		this.$refs.list.get_list();
	},
    async created() {
		// 为页面添加缓存
		this.$keep_route.add(this.$options.name);
        Promise.all([this.$method.get_department(), this.$method.get_group(), this.$method.get_level()]).then(res => {
            this.form_data_seo.list.forEach(item => {
                if (item.name == "department_id") {
                    item.treeData = res[0];
                }
                if (item.name == "group_id") {
                    item.treeData = res[1];
                }
                if (item.name == "level_id") {
                    item.list = res[2];
                }
            });
        });
		this.nature_select()
        this.$nextTick(() => {
			this.$refs.list.fixed_seo_data = this.$route.query;
			this.$refs.list.get_list();
            this.$refs.list.pagination.pageSizeOptions = ['10', '20', '50', '100', '200', '300'];
            this.$refs.list.pagination.showSizeChanger = true;
        })
    },
    methods: {
		nature_select(){
			getCustomerNatureSelect().then(res=>{
				this.form_data_seo.list.forEach(item => {
					if (item.name == "nature_id") {
						item.list = res.data.list.map(it=>{
							return {
								key:it.value,
								value:it.label
							}
						});
					}
				});
			})
		},
		change_dealer(){
			batchDistributor({
				data:{
					old_distributor:this.distributor_update_form.old_distributor.id,// 要修改的经销商
					new_distributor:this.distributor_update_form.new_distributor.id,// 修改后的经销商
					reason:this.distributor_update_form.reason, // 更换原因
					select_type:this.select_type
				}
			}).then(res=>{
				this.distributor_update_form = {};// 清空弹窗中的数据
				this.distributor_update_pop = false;
			})
		},
		// 批量修改经销商弹窗中点击了提交
		distributor_update_confirm(){
			if(!this.distributor_update_form.old_distributor){
				message.error('请选择要修改的'+(this.select_type == 1?'上级配送商':'所属经销商'))
				return;
			}
			if(!this.distributor_update_form.new_distributor){
				message.error('请选择修改后'+(this.select_type == 1?'上级配送商':'所属经销商'))
				return;
			}
			if(this.distributor_update_form.new_distributor.id == this.distributor_update_form.old_distributor.id){
				message.error('要修改的'+(this.select_type == 1?'上级配送商':'所属经销商')+'不能与修改后'+(this.select_type == 1?'上级配送商':'所属经销商')+'为同一人')
				return;
			}
			if(!this.distributor_update_form.reason){
				message.error('请填写修改原因')
				return;
			}
			this.change_dealer();
		},
		// 经销商选择弹窗的提交事件
		select_customer_submit({data}){
			this.distributor_update_form[this.distributor_type] = data;
		},
		// 点击了批量修改经销商按钮，弹出经销商修改弹窗
		start_distributor_update(type){
			this.select_type = type;
			this.distributor_update_form = {};// 清空弹窗中的数据
			this.distributor_update_pop = true;
		},
        get_date(e){
            let arr = e.join(',');
            if(arr.indexOf('505') <0){
                 this.hasdistributor= false
                 this.hasseller = false
            }else {
                this.hasdistributor =true
                this.hasseller = true
            }
        },
        pu_seller(){
            this.visible_updata = true
            this.pu_type = 1;
            push_seller({
                    data:{},
                  // info: true
            }).then(res=>{
                 this.up_cancel= true;
                 this.pu_type = 0;
            }).catch(res=>{
                // this.up_cancel= true;
                //  this.pu_type = 0;
            })
        },
        pu_distributor(){
            this.visible_updata = true
            this.pu_type = 2;
            push_distributor({
                    data:{},
                  // info: true
            }).then(res=>{
                 this.up_cancel= true;
                 this.pu_type = 0;
            }).catch(res=>{
                // this.up_cancel= true;
                //  this.pu_type = 0;
            })
        },
        handleCancel(){
             this.up_cancel= false;
             this.visible_updata = false
        },
		// 前往操作日志页面
		toOpLog(){
			this.$router.push({
				path:'op_log'
			})
		},
        handle_transfer() {
            for (let key in this.transfer_list) {
                this.transfer_list[key] = '';
            }

            this.transfer_type = 'jump_user';
            this.transfer_visible = true;
        },
        get_uid(type) {
            this.transfer_name = type;
            this.user_visible = true;
        },
        start() {
            this.user_visible = true
            this.transfer_type = 'jump_customer';

        },
        transfer_submit(e, type = 'jump_user') {
            let obj = {
                type,
            }

            if (type == 'jump_customer') {
                obj.customer_ids = this.customer_ids,
                    obj.uid = e.id;

            } else if (type == 'jump_user') {
                obj = {
                    ...obj,
                    ...e.values
                }
            }
            try {
                batchModify({
                    data: obj,
                    info: true
                }).then(res => {
                    let _this = this;
                    this.user_visible = false;
                    this.transfer_visible = false;
                    this.$refs.list.get_list();

                    if (type == 'jump_customer') {
                        this.selectedRowKeys = [];
                        this.customer_ids = '';
                    }else if(type == 'change_dealer'){
						this.distributor_update_form = {};// 清空弹窗中的数据
						this.selectedRowKeys = [];// 清空选中的客户id
						this.selectedRows = []; // 清空选中的客户信息
						this.customer_ids = '';// 清空选中的客户id
						this.distributor_update_pop = false;
					}
                })
            } catch {

            }
        },
		handle_all_enable(){
			enableCustomer({
                data: {
                    ids: this.customer_ids
                },
                info: true
            }).then(res => {
                this.$refs.list.get_list();
                this.selectedRowKeys = [];
                this.customer_ids = '';
            })
		},
        handle_all_delete() {
            delCustomer({
                data: {
                    ids: this.customer_ids
                },
                info: true
            }).then(res => {
                this.$refs.list.get_list();
                this.selectedRowKeys = [];
                this.customer_ids = '';
            })
        },
        handle_all_sealed() {
            sealedCustomer({
                data: {
                    ids: this.customer_ids
                },
                info: true
            }).then(res => {
                this.$refs.list.get_list();
                this.selectedRowKeys = [];
                this.customer_ids = '';
            })
        },
        submit({ data }) {
            if (this.transfer_type == 'jump_user') {
                let value = {};
                value[this.transfer_name] = data.id;
                this.$refs.transfer_form.setFieldsValue(value)
                this.transfer_list[this.transfer_name] = data.username
                this.user_visible = false;
                return false;
            }

            this.transfer_submit(data, 'jump_customer');
        },
        add() {
            this.$router.push("/customer/index_edit");
        },
        edit(key) {
            this.$router.push("/customer/index_edit?id=" + key.id);
        },
		goReport() {
			this.$router.push({
				path:"/report/modify_report",
				query:{
					report_key:"custom_customer_list",
					...this.$refs.list.seo_data
				}
			});
		},
    }
};
</script>

<style lang="less" scoped>
	@import "../../assets/less/editor.less";
	@import "./index.less";
</style>